<template>
  <div class="indexBg" v-loading="loading">
    <topNav :active="2"></topNav>
    <div class="catalModule w_1200">
      <div class="classfyDiv">
        <div class="classSub" :class="!tabVal?'active':'hand'" @click="activeHand">{{ $t("商品目录.全部分类") }}</div>
        <div class="classSub" :class="item.id==tabVal?'active':'hand'" @click="activeHand(item)" v-for="item in cateAll" :key="item.id">
          {{ $i18n.locale == "zh" ?item.categoryName:item.categoryNameEn }}
        </div>
      </div>
      <div class="classfyAll">
        <div class="classTop">
          <div class="className">{{ $t("商品目录.元器件共计") }}<span>{{ cateAll.length }}</span>
          </div>
          <div class="classSera">
            <input type="text" v-model="search" @change="getData" :placeholder="$t('商品目录.请输入关键字')" />
            <div class="hand" @click="getData">{{ $t("商品目录.搜索") }}</div>
          </div>
        </div>
        <div class="classDetail">
          <div class="classItem" v-if="item.children.length>0" v-for="item in cate1" @click="goUrl(item)">
            <div class="clasTit hand hover">
              <span>{{ $i18n.locale == "zh" ?item.categoryName:item.categoryNameEn }}</span>
              ({{ item.num }})<img src="@/assets/sub/claRig.png" alt="" />
            </div>
            <ul>
              <li class="hand" v-for="(item2,index2) in item.children" @click.stop="goUrl(item2)">
                <span class="hover">{{ $i18n.locale == "zh" ?item2.categoryName:item2.categoryNameEn }}({{ item2.num }})</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <bottomNav></bottomNav>
  </div>
</template>
<script>
import topNav from "@/component/topNav/index.vue";
import bottomNav from "@/component/bottomNav/index.vue";
import { categoryList } from "@/api/api";
import {productLine} from '@/util/http'
export default {
  components: { topNav, bottomNav },
  data() {
    return {
      loading: false,
      search:'',
      cate1: [],
      cateAll: [],
      tabVal:'',
    };
  },
  mounted() {
    this.getData();
    this.getAll();
  },
  methods: {
    activeHand(item){
      if(!item){
        this.tabVal=''
      }else{
        this.tabVal=item.id
      }
      this.getData()
    },
    async getAll() {//全部分类
      const res = await categoryList({
        parentId:0,
      });
      this.cateAll = res.data;
    },
    async getData() {//查询子类
      const res = await productLine({
        categoryName:this.search,
        categoryId:this.tabVal
      });
      this.cate1 = res.data;
    },
    goUrl(item) {
      this.$router.push({
         path: "/catalogueTwo",
         query: {
           classfyId: item.id,
           categoryName:item.categoryName,
           categoryNameEn:item.categoryNameEn,
           num:item.num
          }
        })
    },
  },
};
</script>
<style lang="scss" scoped>
.indexBg {
  width: 100%;
  .catalModule {
    margin: 40px auto;
    .classfyDiv {
      background: #ffffff;
      border: 1px solid #eeeeee;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-row-gap: 15px;
      box-sizing: border-box;
      padding: 30px 50px;
      .classSub {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 128px;
        line-height: 34px;
        height: 34px;
        text-align: center;
        background: #f9f9f9;
        font-size: 14px;
        color: #000000;
        border-radius: 6px 6px 6px 6px;
        border: 1px solid #d2d5e5;
      }
      .active {
        background: #012f9c;
        color: #fff;
        // margin-right: 20px;
      }
    }
    .classfyAll {
      margin-top: 10px;
      .classTop {
        margin: 25px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .className {
          font-weight: 400;
          font-size: 18px;
          color: #000000;
          span {
            font-weight: bold;
            font-size: 23px;
          }
        }
        .classSera {
          display: flex;
          align-items: center;
          input {
            height: 40px;
            width: 284px;
            background: #ffffff;
            text-indent: 1rem;
            border: 1px solid #cccccc;
          }
          div {
            width: 93px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background: #012f9c;
            font-size: 18px;
            border: 1px solid #012f9c;
            color: #ffffff;
          }
        }
      }
      .classDetail {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        .classItem {
          width: 380px;
          height: 280px;
          box-sizing: border-box;
          background: #fff;
          overflow: hidden;
          border: 1px solid #eeeeee;
          .clasTit {
            background: #f5f6f9;
            font-size: 20px;
            color: #000000;
            padding: 10px 30px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            img {
              width: 10px;
              height: 19px;
              margin-left: 10px;
            }
          }
          ul {
            padding: 30px 50px;
            li {
              list-style: disc;
              padding: 8px 0;
              color: #012f9c;
              span {
                font-size: 18px;
                color: #666666;
              }
            }
          }
        }
      }
    }
  }
}
</style>
