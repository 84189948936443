<template>
  <div class="box">
    <div class="up w_1200">
      <div class="four">
        <div class="f_item" v-for="(item, index) in four" :key="index">
          <img
            :src="require('@/assets/home/icon/' + item.img + '.png')"
            alt=""
          />
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="down">
      <div
        style="padding: 45px 40px 45px 40px; box-sizing: border-box"
        class="w_1200"
      >
        <div class="ul">
          <div class="left">
            <div class="cell" v-for="(item, index) in five" :key="index">
              <div class="c_1">{{ item.title }}</div>
              <div v-for="(item_1, index_1) in item.list" @click="bottomClick(item_1)" :key="index_1" class="c_2 hand hover">
                {{ item_1.text }}
              </div>
            </div>
          </div>
          <div class="right">
            <div class="r_1">
              <img class="i" src="@/assets/home/icon/server.png" alt="" />
              <span class="s_1">400-870-9968</span>
              <span class="s_2">{{$t('首页.服务时间')}}：8:30-17:30</span>
              <div class="btn hand">
                <img src="@/assets/home/icon/message.png" alt="" />
                {{$t('首页.在线销售')}}
              </div>
              <span class="s_2">{{$t('首页.媒体合作')}}：13774699238</span>
            </div>
            <div class="r_2">
              <img src="@/assets/home/code.png" alt="" />
              <span class="s_2">{{$t('首页.关注贞光公众号')}}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="bg_F9">
        <div class="w_1200">
          <div class="num_box">
            <div class="hand hover">{{$t('首页.字母索引')}}:</div>
            <div
              class="hand hover"
              v-for="(item, index) in numIndex"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <div style="background-color: #353535">
      <div class="xd w_1200">
        <div class="l_1">
          <a class="hand hover" @click="urlInfo()">{{$t('首页.新手上路')}}</a>
          <div class="line"></div>
          <a class="hand hover" @click="urlInfo()">{{$t('首页.免责声明')}}</a>
          <div class="line"></div>
          <a class="hand hover" @click="urlInfo()">{{$t('首页.销售条款')}}</a>
          <div class="line"></div>
          <a class="hand hover" @click="urlInfo()">{{$t('首页.联系我们')}}</a>
        </div>
        <div class="l_2">
          Copyright@2024&nbsp;{{$t('首页.贞光科技有限公司')}}&nbsp;
          <img src="@/assets/home/icon/popo.png" alt="" />
          <a class="hover" href="">{{$t('首页.公网安备')}}35021102001214{{$t('首页.号')}}</a>&nbsp
          <a class="hover" href="">XXICP{{$t('首页.备')}}17005748{{$t('首页.号')}}-1</a> &nbsp;
          <a class="hover" href="">XXICP{{$t('首页.备')}}17005748{{$t('首页.号')}}-1</a>
        </div>
        <div class="l_3">
          <img
            v-for="(item, index) in 8"
            :key="index"
            :src="require('@/assets/home/' + 'b_' + (index + 1) + '.png')"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 字母索引
      numIndex: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
      ],
      four: [
        {
          name:this.$t('首页.原厂授权'),
          img: "f_1",
        },
        {
          name:this.$t('首页.自营现货'),
          img: "f_2",
        },
        {
          name:this.$t('首页.品类齐全'),
          img: "f_3",
        },
        {
          name:this.$t('首页.满99包邮'),
          img: "f_4",
        },
      ],
      five: [
        {
          title:this.$t('首页.关于贞光'),
          list: [
            {
              text:this.$t('首页.关于我们'),
              url: "/about",
            },
            {
              text:this.$t('首页.荣誉风采'),
              url: "/honor",
            },
            {
              text:this.$t('首页.授权代理'),
              url: "/agency",
            },
            {
              text:this.$t('首页.联系我们'),
              url: "/contact",
            },
          ],
        },
        {
          title:this.$t('首页.新手入门'),
          list: [
            {
              text:this.$t('首页.注册登录'),
              url: "/footClass",
              val:'0-0'
            },
            {
              text:this.$t('首页.找回密码'),
              url: "/footClass",
              val:'0-1'
            },
            {
              text:this.$t('首页.收藏功能'),
              url: "/footClass",
              val:'0-2'
            },
            {
              text:this.$t('首页.BOM配单'),
              url: "/footClass",
              val:'0-3'
            },
          ],
        },
        {
          title:this.$t('首页.购物指南'),
          list: [
            {
              text:this.$t('首页.购物流程'),
              url: "/footClass",
              val:'1-0'
            },
            {
              text:this.$t('首页.搜索商品'),
              url: "/footClass",
              val:'1-1'
            },
            {
              text:this.$t('首页.订单管理'),
              url: "/footClass",
              val:'1-3'
            },
            {
              text:this.$t('首页.合同下载'),
              url: "/footClass",
              val:'1-4'
            },
          ],
        },
        {
          title:this.$t('首页.配送支付'),
          list: [
            {
              text:this.$t('首页.支付方式'),
              url: "/footClass",
              val:'2-0'
            },
            {
              text:this.$t('首页.账户信息'),
              url: "/footClass",
              val:'2-1'
            },
            {
              text:this.$t('首页.支付安全'),
              url: "/footClass",
              val:'2-2'
            },
            {
              text:this.$t('首页.运费说明'),
              url: "/footClass",
              val:'2-3'
            },
          ],
        },
        {
          title:this.$t('首页.售后服务'),
          list: [
            {
              text:this.$t('首页.售后服务'),
              url: "/footClass",
              val:'3-0'
            },
            {
              text:this.$t('首页.发票须知'),
              url: "/footClass",
              val:'3-1'
            },
            {
              text:this.$t('首页.销售条款'),
              url: "/footClass",
              val:'3-2'
            },
            {
              text:this.$t('首页.重新交付'),
              url: "/footClass",
              val:'3-3'
            },
          ],
        },
      ],
    };
  },
  methods:{
    urlInfo(){
      this.$router.push({ path: "/footClass" });
    },
    bottomClick(item){
      this.$router.push({
        path: item.url,
        query: {subIndex: item.val}
      })
      // this.$router.push(item.url)
    }
  }
};
</script>

<style lang='scss' scoped>
.box {
  padding-top: 31px;
  background-color: #ffffff;
}
.four {
  padding: 0 40px;
  padding-bottom: 32px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
  .f_item {
    display: flex;
    align-items: center;
    height: 42px;
    font-size: 16px;
    color: #000000;
    img {
      margin-right: 18px;
    }
  }
}
.ul {
  display: flex;
}
.ul .left {
  margin-right: 60px;
  display: flex;
  width: 72%;
  justify-content: space-between;
  .c_1 {
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 11px;
    width: 122px;
    text-align: center;
    font-size: 16px;
    color: #000000;
  }
  .c_2 {
    margin-top: 15px;
    width: 122px;
    text-align: center;
    font-size: 13px;
    color: #000000;
  }
}
.ul .right {
  display: flex;
  justify-content: space-between;
  flex: 1;
  //   background-color: pink;
  color: #000000;
  .s_1 {
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 13px;
  }
  .s_2 {
    font-size: 11px;
  }
  .r_1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    .i {
      width: 35px;
      height: 35px;
      margin-bottom: 12px;
    }
    .btn {
      margin: 8px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      width: 115px;
      height: 26px;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #ff6600;
      font-size: 13px;
      color: #ff6600;
      img {
        width: 15px;
        height: 13px;
        margin-right: 7px;
      }
    }
  }
  .r_2 {
    padding-top: 15px;
    text-align: center;
    width: 90px;
  }
}
.num_box {
  padding: 0 75px;
  padding-right: 65px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  color: #000000;
}
.xd {
  height: 126px;
  color: #ffffff;
}
.xd {
  box-sizing: border-box;
  padding: 15px 21px;
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    color: #ffffff;
    text-decoration: none;
  }

  .l_1 {
    margin-bottom: 11px;
    display: flex;
    font-size: 13px;
    align-items: center;
    .line {
      margin: 0 20px;
      width: 2px;
      height: 8px;
      background-color: #ffffff;
    }
  }
  .l_2 {
    letter-spacing: 1px;
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    font-size: 11px;
    height: 20px;
    img {
      margin: 0 2px;
      width: 20px;
      height: 20px;
    }
  }
  .l_3 {
    img {
      margin: 0 10px;
    }
  }
}
</style>
