<template>
  <div class="bg">
    <loginTop></loginTop>
    <div class="bor">
      <!-- 账号登录 -->
      <div v-if="loginType != 3" class="loginRight">
        <div class="wxLogin">
          <div class="wxTips"><img src="@/assets/login/icon11.png"/>{{ $t('登录.微信扫码登录') }}
          </div>
          <img class="rigWx hand" src="@/assets/login/icon7.png" @click="tabLogin(3)"/>
        </div>
        <div class="inpDiv">
          <div class="tabLogin">
            <span :class="loginType == 1 ? 'active' : ''" @click="loginType = 1">{{
                $t('登录.账号登录')
              }}</span>
            <span :class="loginType == 2 ? 'active' : ''" @click="loginType = 2">{{
                $t('登录.短信登录')
              }}</span>
            <span :class="loginType == 100 ? 'active' : ''" @click="loginType = 100">{{
                $t('邮箱登录')
              }}</span>
          </div>
          <div class="inputDiv">
            <div v-if="loginType != 100" class="input">
              <img alt="" src="@/assets/login/icon3.png">
              <input v-model="loginBody.username" :placeholder="$t('登录.请输入手机号')"
                     maxlength="11"
                     type="number">
            </div>
            <div v-if="loginType == 100" class="input">
              <i class="el-icon-message"></i>
              <input v-model="loginBody.username" :placeholder="$t('请输入邮箱')" type="text">
            </div>
            <div v-if="loginType == 1 || loginType == 100 " class="input">
              <img alt="" src="@/assets/login/icon2.png">
              <input v-model="loginBody.password" :placeholder="$t('登录.请输入登录密码')"
                     :type="pwdState ? '' : 'password'">
              <img alt="" class="cloIcon hand"
                   src="@/assets/login/icon12.png" @click="pwdState = !pwdState">
            </div>
            <div v-if="loginType == 2" v-loading="loading" class="input inputCode">
              <img alt="" src="@/assets/login/icon4.png">
              <input v-model="codeVal" :placeholder="$t('登录.请输入短信验证码')" type="number">
              <span v-if="!getCodeBlo" class="code" @click="getCode()">{{
                  $t('登录.发送验证码')
                }}</span>
              <span v-else-if="getCodeBlo && getCodeNum >= 1" class="code">{{ getCodeNum }}{{
                  $t('登录.秒后获取')
                }}</span>
              <span v-else-if="getCodeBlo && getCodeNum < 1" class="code" @click="getCode()">{{
                  $t('登录.重新获取')
                }}</span>
              <span v-else class="code">{{ count }}s</span>
            </div>
            <div class="xz">
              <div class="stateBtn" @click="stateClick">
                <img
                    :src="state ? require('@/assets/login/icon14.png') : require('@/assets/login/icon13.png')"
                    alt="">
                {{ $t('登录.记录登录状态') }}
              </div>
              <div class="linkBtn">
                <router-link to="/forget">{{ $t('登录.忘记密码') }}?</router-link>
              </div>
            </div>
          </div>
          <div v-loading="loading" class="loginBtn" @click="loginClick">{{ $t('登录.登录') }}</div>
          <div class="registBtn">
            <router-link to="/regist">{{ $t('登录.立即注册') }}</router-link>
          </div>
          <div v-if="loginType == 2" class="tipsMsg">
            <img alt="" src="@/assets/login/icon10.png">
            {{ $t('登录.未注册的手机号验证后自动注册') }}
          </div>
        </div>
      </div>
      <!-- 微信扫码登录 -->
      <div v-if="loginType == 3" class="loginRight">
        <div class="wxLogin">
          <div class="wxTips">{{ $t('登录.账号密码登录') }}</div>
          <img class="rigWx hand" src="@/assets/login/icon8.png" @click="tabLogin(1)"/>
        </div>
        <div class="wxDiv">
          <div class="wxTit"><img src="@/assets/login/icon11.png"/>{{ $t('登录.微信扫码登录') }}
          </div>
          <img class="wxEwm" src="@/assets/login/icon15.png"/>
        </div>
      </div>
    </div>
    <loginBot></loginBot>
  </div>
</template>

<script>
import loginTop from '@/component/login/loginTop.vue'
import loginBot from '@/component/login/loginBot.vue'
import {login, send} from '@/api/api'
import {setToken} from '../../util/cookie.js'
import {encrypt} from '@/util/encryption'
import Cookies from 'js-cookie'

export default {
  name: "login",
  components: {loginTop, loginBot},
  data() {
    return {
      pwdState: false,
      loginType: 1,//登录方式 1：密码，2：短信验证码，3：微信
      loginBody: {
        username: '',
        password: ''
      },
      codeVal: '',//验证码
      getCodeNum: 5,
      getCodeBlo: false,
      count: '',
      state: false,//记录登录状态
      loading: false
    }
  },
  onload: {},
  mounted() {
    // Cookies.remove('loginInfo')
    if (Cookies.get('loginInfo')) {
      this.loginBody = JSON.parse(Cookies.get('loginInfo'))
      this.state = true
    }
  },
  methods: {
    tabLogin(num) {//切换登录方式
      this.loginType = num
    },
    stateClick() {//记录登录状态
      this.state = !this.state;
    },
    async loginClick() {//登录
      if (this.loginBody.username == '') {
        this.$message({
          type: 'warning',
          message: this.$t('登录.手机号不能为空'),
          offset: 100
        });
        return
      }
      if (this.loginType == 1) {
        if (this.loginBody.password == '') {
          this.$message({
            type: 'warning',
            message: this.$t('登录.密码不能为空'),
            offset: 100
          });
          return
        }
      } else if (this.loginType == 2) {
        if (this.codeVal == '') {
          this.$message({
            type: 'warning',
            message: this.$t('登录.验证码不能为空'),
            offset: 100
          });
          return
        }
      }
      let res;
      if (this.loginType == 1 || this.loginType == 100) {
        this.loading = true;
        try {
          res = await login({
            username: this.loginBody.username,
            password: encrypt(this.loginBody.password),
            loginWay: this.loginType
          })
          this.loading = false;
          setToken(res.token)
          Cookies.set('userInfo', JSON.stringify(res.userDetails))
          if (this.state) {
            let loginInfo = JSON.stringify(this.loginBody);
            Cookies.set('loginInfo', loginInfo)
          } else {
            Cookies.set('loginInfo', '')
          }
          this.$router.push('/home')
        } catch {
          this.loading = false;
        }
      } else if (this.loginType == 2) {
        this.loading = true;
        try {
          res = await login({
            username: this.loginBody.username,
            password: this.codeVal,
            loginWay: this.loginType
          })
          console.log('res:', res)
          this.loading = false;
          if (res.code == 200) {
            setToken(res.token)
            Cookies.set('userInfo', JSON.stringify(res.userDetails))
          }
          if (this.state) {
            let loginInfo = JSON.stringify(this.loginBody);
            Cookies.set('loginInfo', loginInfo)
          } else {
            Cookies.set('loginInfo', '')
          }
          this.$router.push('/home')
        } catch {
          this.loading = false;
        }
      }

    },
    async getCode() {
      if (this.loginBody.username == '') {
        this.$message({
          type: 'warning',
          message: this.$t('登录.手机号不能为空'),
          offset: 100
        });
        return
      }
      const res = await send({phoneNumber: this.loginBody.username, type: '2'})//1：注册，2：登录，3：修改密码
      this.loading = true;
      try {
        setTimeout(() => {
          this.loading = false;
          this.codeVal = res.smsCode
          this.$message({
            // showClose: true,
            type: 'warning',
            message: this.$t('登录.验证码已发送'),
            offset: 100
          });
          this.getCodeNum = 60
          this.countDown()
        }, 500)
      } catch {
      }

    },
    //倒计时
    countDown() {
      this.getCodeBlo = true
      this.setInterval = setInterval(() => {
        if (this.getCodeNum >= 1) {
          this.getCodeNum--
        } else {
          clearInterval(this.setInterval)
        }
      }, 1000)
    },
  }
}
</script>

<style lang="scss" scoped>
.bg {
  background-image: url("/src/assets/login/logoBg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: calc(100vh);

  /*background: antiquewhite;*/
  /*width: 100%;*/
  /*height:100%;*/
  /*height:100vh;*/
  /*padding:57px 210px;*/
  .bor {
    height: 820px;
    padding: 0 360px 0 0;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;

    .loginRight {
      width: 574px;
      height: 574px;
      background: #FFFFFF;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 10px 30px;

      .wxLogin {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;

        .wxTips {
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #999999;
          font-size: 13px;
          color: #999999;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 116px;
          height: 30px;

          img {
            width: 17px;
            height: 17px;
            margin-right: 3px;
          }
        }

        .rigWx {
          width: 49px;
          height: 49px;
          margin-left: 8px;
        }
      }

      .inpDiv {
        width: 335px;
        margin: auto;
      }

      .tabLogin {
        cursor: pointer;
        display: flex;
        justify-content: center;
        margin-top: 36px;

        span {
          font-size: 20px;
          display: inline-block;
          width: 167px;
          text-align: center;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #999;
          line-height: 21px;
          padding-bottom: 11px;
          border-bottom: solid 2px #D9D9D9;
        }

        .active {
          color: #000;
          border-bottom: solid 2px #012F9C;
        }
      }

      .wxDiv {
        width: 268px;
        margin: 60px auto;

        .wxTit {
          font-size: 24px;
          color: #333333;
          margin: 30px 0;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 26px;
            height: 26px;
            margin-right: 6px;
          }
        }

        .wxEwm {
          width: 268px;
          height: 268px;
        }
      }


      .inputDiv {
        .inputCode {
          input {

            width: 50% !important;
          }

          span {
            text-align: right;
            //border: solid;
            display: inline-block;
            width: 35%;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            font-size: 13px;
            color: #012F9C;
            line-height: 21px;
            cursor: pointer;
          }
        }

        .input {
          //height: 60px;
          padding: 13px 20px;
          background: #FFFFFF;
          width: 334px;
          height: 46px;
          box-sizing: border-box;
          border-radius: 23px 23px 23px 23px;
          border: 1px solid #999999;
          display: flex;
          align-items: center;
          margin-top: 40px;

          img {
            width: 17px;
            height: 17px;
          }

          .cloIcon {
            width: 20px;
            height: 20px;
          }

          input {
            width: 78%;
            margin: 0 10px;
            border: none;
            font-size: 16px;
          }

          input::-webkit-input-placeholder {
            /*WebKit browsers*/
            line-height: 32px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            font-size: 13px;
            color: #333333;
          }

          input::-moz-input-placeholder {
            /*Mozilla Firefox*/
            line-height: 32px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            font-size: 13px;
            color: #333333;
          }

          input::-ms-input-placeholder {
            /*Internet Explorer*/
            line-height: 32px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            font-size: 13px;
            color: #333333;
          }
        }

        .xz {
          padding: 0 20px;
          margin: 20px 0 40px 0;
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          line-height: 29px;

          .stateBtn {
            color: #999999;
            cursor: pointer;

            img {
              width: 16px;
              height: 16px;
              vertical-align: middle;
            }
          }

          .linkBtn {
            a {
              cursor: pointer;
              font-size: 13px;
              color: #999999;
            }
          }
        }
      }

      .loginBtn {
        width: 330px;
        background: #012F9C;
        border-radius: 25px;
        text-align: center;
        line-height: 50px;
        color: #fff;
        cursor: pointer;
      }

      .registBtn {
        text-align: right;
        margin-top: 20px;

        a {
          cursor: pointer;
          font-size: 13px;
          color: #012F9C;
        }
      }

      .tipsMsg {
        img {
          width: 24px;
          height: 24px;
          margin-right: 6px;
        }

        margin-top: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        color: #333333;
      }
    }
  }

}
</style>
